import * as i0 from '@angular/core';
import { TemplateRef, Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, Optional, Self, Inject, ViewChild, ContentChild, HostBinding, Directive, NgModule } from '@angular/core';
import * as i1$1 from '@taiga-ui/core';
import { tuiAsDataList, TUI_TEXTFIELD_CLEANER, TUI_TEXTFIELD_SIZE, tuiAsDataListHost, tuiAsOptionContent, TuiDataListDirective, TuiPrimitiveTextfieldComponent, TuiHostedDropdownComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiWrapperModule, TuiTextfieldControllerModule, TuiDataListModule, TuiTextfieldComponent } from '@taiga-ui/core';
import { AbstractTuiNativeSelect } from '@taiga-ui/kit/abstract';
import * as i1 from '@taiga-ui/kit/components/data-list-wrapper';
import { TuiDataListWrapperModule } from '@taiga-ui/kit/components/data-list-wrapper';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@taiga-ui/kit/pipes';
import { TuiStringifyContentPipeModule } from '@taiga-ui/kit/pipes';
import * as i5 from '@angular/forms';
import { NgControl } from '@angular/forms';
import * as i2 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, AbstractTuiNullableControl, tuiIsNativeFocused, TUI_IS_MOBILE, tuiAsFocusableItemAccessor, tuiAsControl, TuiActiveZoneModule } from '@taiga-ui/cdk';
import { TUI_ARROW_MODE, TuiArrowModule } from '@taiga-ui/kit/components/arrow';
import { TUI_SELECT_OPTION, TuiSelectOptionModule } from '@taiga-ui/kit/components/select-option';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/kit/providers';
import { TUI_ITEMS_HANDLERS } from '@taiga-ui/kit/tokens';
import * as i4$1 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
const _c0 = ["tuiSelect", ""];
function TuiNativeSelectComponent_tui_data_list_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-data-list-wrapper", 4);
    i0.ɵɵpipe(1, "tuiStringifyContent");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r0.disabledItemHandler || ctx_r0.itemsHandlers.disabledItemHandler)("itemContent", i0.ɵɵpipeBind1(1, 3, ctx_r0.stringify))("items", ctx_r0.items);
  }
}
function TuiNativeSelectComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiNativeSelectComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.placeholder, "\n");
  }
}
function TuiNativeSelectComponent_option_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 6);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r0.disabledItemHandler ? ctx_r0.disabledItemHandler(option_r2) : ctx_r0.itemsHandlers.disabledItemHandler(option_r2))("selected", ctx_r0.selected(option_r2))("value", ctx_r0.stringify(option_r2));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.stringify(option_r2), "\n");
  }
}
const _c1 = ["tuiSelect", "", "labels", ""];
function TuiNativeSelectGroupComponent_tui_data_list_wrapper_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-data-list-wrapper", 4);
    i0.ɵɵpipe(1, "tuiStringifyContent");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r0.disabledItemHandler || ctx_r0.itemsHandlers.disabledItemHandler)("itemContent", i0.ɵɵpipeBind1(1, 4, ctx_r0.stringify))("items", ctx_r0.items)("labels", ctx_r0.labels);
  }
}
function TuiNativeSelectGroupComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiNativeSelectGroupComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 5);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.placeholder, "\n");
  }
}
function TuiNativeSelectGroupComponent_optgroup_4_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r2 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r0.disabledItemHandler ? ctx_r0.disabledItemHandler(option_r2) : ctx_r0.itemsHandlers.disabledItemHandler(option_r2))("selected", ctx_r0.selected(option_r2))("value", ctx_r0.stringify(option_r2));
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r0.stringify(option_r2), " ");
  }
}
function TuiNativeSelectGroupComponent_optgroup_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "optgroup", 6);
    i0.ɵɵtemplate(1, TuiNativeSelectGroupComponent_optgroup_4_option_1_Template, 2, 4, "option", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const group_r3 = ctx.$implicit;
    const index_r4 = ctx.index;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("label", ctx_r0.labels[index_r4]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", group_r3);
  }
}
const _c2 = "[_nghost-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0}";
const _c3 = ["*", [["input"]], [["select"]]];
const _c4 = ["*", "input", "select"];
const _c5 = (a0, a1) => ({
  $implicit: a0,
  active: a1
});
function TuiSelectComponent_ng_template_4_ng_content_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 2, ["*ngIf", "isMobile"]);
  }
}
function TuiSelectComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiSelectComponent_ng_template_4_ng_content_0_Template, 1, 0, "ng-content", 6);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.isMobile);
  }
}
function TuiSelectComponent_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 7);
  }
}
function TuiSelectComponent_div_7_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 10);
  }
  if (rf & 2) {
    const text_r3 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("textContent", text_r3);
  }
}
function TuiSelectComponent_div_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵtemplate(1, TuiSelectComponent_div_7_div_1_Template, 1, 1, "div", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.computedContent)("polymorpheusOutletContext", i0.ɵɵpureFunction2(2, _c5, ctx_r1.value, ctx_r1.computedFocused));
  }
}
function TuiSelectComponent_ng_template_8_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiSelectComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 11);
    i0.ɵɵtemplate(1, TuiSelectComponent_ng_template_8_ng_container_1_Template, 1, 0, "ng-container", 12);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r1.arrow);
  }
}
class TuiNativeSelectComponent extends AbstractTuiNativeSelect {
  constructor() {
    super(...arguments);
    this.items = [];
  }
  get stringify() {
    return this.host.stringify;
  }
  selected(option) {
    return this.control.value === option;
  }
  onValueChange(index) {
    var _a;
    this.host.onValueChange(((_a = this.items) === null || _a === void 0 ? void 0 : _a[index]) || null);
  }
}
TuiNativeSelectComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiNativeSelectComponent_BaseFactory;
  return function TuiNativeSelectComponent_Factory(t) {
    return (ɵTuiNativeSelectComponent_BaseFactory || (ɵTuiNativeSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiNativeSelectComponent)))(t || TuiNativeSelectComponent);
  };
})();
TuiNativeSelectComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiNativeSelectComponent,
  selectors: [["select", "tuiSelect", "", 3, "labels", "", 3, "multiple", ""]],
  hostVars: 4,
  hostBindings: function TuiNativeSelectComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function TuiNativeSelectComponent_change_HostBindingHandler($event) {
        return ctx.onValueChange($event.target.options.selectedIndex - (ctx.emptyOption ? 1 : 0));
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("disabled", ctx.host.disabled || ctx.control.readOnly)("tabIndex", ctx.host.focusable ? 0 : -1)("value", ctx.host.value);
      i0.ɵɵattribute("aria-invalid", ctx.host.invalid);
    }
  },
  inputs: {
    items: "items"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDataList(TuiNativeSelectComponent), {
    provide: TemplateRef,
    deps: [TuiNativeSelectComponent],
    useFactory: ({
      datalist
    }) => datalist
  }, {
    provide: AbstractTuiNativeSelect,
    useExisting: TuiNativeSelectComponent
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c0,
  decls: 5,
  vars: 5,
  consts: [[3, "disabledItemHandler", "itemContent", "items", 4, "tuiDataList"], [4, "ngIf"], ["disabled", "", "selected", "", "value", "", 4, "ngIf"], [3, "disabled", "selected", "value", 4, "ngFor", "ngForOf"], [3, "disabledItemHandler", "itemContent", "items"], ["disabled", "", "selected", "", "value", ""], [3, "disabled", "selected", "value"]],
  template: function TuiNativeSelectComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiNativeSelectComponent_tui_data_list_wrapper_0_Template, 2, 5, "tui-data-list-wrapper", 0)(1, TuiNativeSelectComponent_ng_container_1_Template, 1, 0, "ng-container", 1);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵtemplate(3, TuiNativeSelectComponent_option_3_Template, 2, 1, "option", 2)(4, TuiNativeSelectComponent_option_4_Template, 2, 4, "option", 3);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx.control.control == null ? null : ctx.control.control.valueChanges));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.emptyOption);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i1.TuiDataListWrapperComponent, i1$1.TuiDataListDirective, i3.NgIf, i3.NgForOf, i4.TuiStringifyContentPipe, i3.AsyncPipe],
  styles: ["[_nghost-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeSelectComponent, [{
    type: Component,
    args: [{
      selector: 'select[tuiSelect]:not([labels]):not([multiple])',
      templateUrl: './native-select.template.html',
      styleUrls: ['./native-select.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataList(TuiNativeSelectComponent), {
        provide: TemplateRef,
        deps: [TuiNativeSelectComponent],
        useFactory: ({
          datalist
        }) => datalist
      }, {
        provide: AbstractTuiNativeSelect,
        useExisting: TuiNativeSelectComponent
      }],
      host: {
        '[attr.aria-invalid]': 'host.invalid',
        '[disabled]': 'host.disabled || control.readOnly',
        '[tabIndex]': 'host.focusable ? 0 : -1',
        '[value]': 'host.value',
        '(change)': 'onValueChange($event.target.options.selectedIndex - (emptyOption ? 1 : 0))'
      }
    }]
  }], null, {
    items: [{
      type: Input
    }]
  });
})();
class TuiNativeSelectGroupComponent extends AbstractTuiNativeSelect {
  constructor() {
    super(...arguments);
    this.items = [];
    this.labels = [];
  }
  get stringify() {
    return this.host.stringify;
  }
  selected(option) {
    return this.control.value === option;
  }
  onValueChange(index) {
    var _a;
    const flatItems = (_a = this.items) === null || _a === void 0 ? void 0 : _a.reduce((acc, val) => acc.concat(val), []);
    this.host.onValueChange((flatItems === null || flatItems === void 0 ? void 0 : flatItems[index]) || null);
  }
}
TuiNativeSelectGroupComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiNativeSelectGroupComponent_BaseFactory;
  return function TuiNativeSelectGroupComponent_Factory(t) {
    return (ɵTuiNativeSelectGroupComponent_BaseFactory || (ɵTuiNativeSelectGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiNativeSelectGroupComponent)))(t || TuiNativeSelectGroupComponent);
  };
})();
TuiNativeSelectGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiNativeSelectGroupComponent,
  selectors: [["select", "tuiSelect", "", "labels", "", 3, "multiple", ""]],
  hostVars: 4,
  hostBindings: function TuiNativeSelectGroupComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function TuiNativeSelectGroupComponent_change_HostBindingHandler($event) {
        return ctx.onValueChange($event.target.options.selectedIndex - (ctx.emptyOption ? 1 : 0));
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("disabled", ctx.host.disabled || ctx.control.readOnly)("tabIndex", ctx.host.focusable ? 0 : -1)("value", ctx.host.value);
      i0.ɵɵattribute("aria-invalid", ctx.host.invalid);
    }
  },
  inputs: {
    items: "items",
    labels: "labels"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDataList(TuiNativeSelectGroupComponent), {
    provide: TemplateRef,
    deps: [TuiNativeSelectGroupComponent],
    useFactory: ({
      datalist
    }) => datalist
  }, {
    provide: AbstractTuiNativeSelect,
    useExisting: TuiNativeSelectGroupComponent
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c1,
  decls: 5,
  vars: 5,
  consts: [[3, "disabledItemHandler", "itemContent", "items", "labels", 4, "tuiDataList"], [4, "ngIf"], ["disabled", "", "selected", "", "value", "", 4, "ngIf"], [3, "label", 4, "ngFor", "ngForOf"], [3, "disabledItemHandler", "itemContent", "items", "labels"], ["disabled", "", "selected", "", "value", ""], [3, "label"], [3, "disabled", "selected", "value", 4, "ngFor", "ngForOf"], [3, "disabled", "selected", "value"]],
  template: function TuiNativeSelectGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiNativeSelectGroupComponent_tui_data_list_wrapper_0_Template, 2, 6, "tui-data-list-wrapper", 0)(1, TuiNativeSelectGroupComponent_ng_container_1_Template, 1, 0, "ng-container", 1);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵtemplate(3, TuiNativeSelectGroupComponent_option_3_Template, 2, 1, "option", 2)(4, TuiNativeSelectGroupComponent_optgroup_4_Template, 2, 2, "optgroup", 3);
    }
    if (rf & 2) {
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx.control.control == null ? null : ctx.control.control.valueChanges));
      i0.ɵɵadvance(2);
      i0.ɵɵproperty("ngIf", ctx.emptyOption);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i1.TuiDataListGroupWrapperComponent, i1$1.TuiDataListDirective, i3.NgIf, i3.NgForOf, i4.TuiStringifyContentPipe, i3.AsyncPipe],
  styles: [_c2],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeSelectGroupComponent, [{
    type: Component,
    args: [{
      selector: 'select[tuiSelect][labels]:not([multiple])',
      templateUrl: './native-select-group.template.html',
      styleUrls: ['./native-select.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataList(TuiNativeSelectGroupComponent), {
        provide: TemplateRef,
        deps: [TuiNativeSelectGroupComponent],
        useFactory: ({
          datalist
        }) => datalist
      }, {
        provide: AbstractTuiNativeSelect,
        useExisting: TuiNativeSelectGroupComponent
      }],
      host: {
        '[attr.aria-invalid]': 'host.invalid',
        '[disabled]': 'host.disabled || control.readOnly',
        '[tabIndex]': 'host.focusable ? 0 : -1',
        '[value]': 'host.value',
        '(change)': 'onValueChange($event.target.options.selectedIndex - (emptyOption ? 1 : 0))'
      }
    }]
  }], null, {
    items: [{
      type: Input
    }],
    labels: [{
      type: Input
    }]
  });
})();
const TUI_SELECT_DEFAULT_OPTIONS = {
  valueContent: ''
};
/**
 * Default parameters for Select component
 */
const TUI_SELECT_OPTIONS = tuiCreateToken(TUI_SELECT_DEFAULT_OPTIONS);
function tuiSelectOptionsProvider(options) {
  return tuiProvideOptions(TUI_SELECT_OPTIONS, options, TUI_SELECT_DEFAULT_OPTIONS);
}
class TuiSelectComponent extends AbstractTuiNullableControl {
  constructor(control, cdr, textfieldCleaner, textfieldSize, arrowMode, itemsHandlers, options, isMobile) {
    super(control, cdr);
    this.textfieldCleaner = textfieldCleaner;
    this.textfieldSize = textfieldSize;
    this.arrowMode = arrowMode;
    this.itemsHandlers = itemsHandlers;
    this.options = options;
    this.isMobile = isMobile;
    this.stringify = this.itemsHandlers.stringify;
    this.identityMatcher = this.itemsHandlers.identityMatcher;
    this.valueContent = this.options.valueContent;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get arrow() {
    return !this.interactive ? this.arrowMode.disabled : this.arrowMode.interactive;
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.textfield) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    return tuiIsNativeFocused(this.nativeFocusableElement) || !!this.hostedDropdown && this.hostedDropdown.focused;
  }
  get nativeDropdownMode() {
    return !!this.nativeSelect && this.isMobile;
  }
  get computedValue() {
    return this.value === null ? '' : this.stringify(this.value) || ' ';
  }
  get computedContent() {
    return this.valueContent || this.computedValue;
  }
  onValueChange(value) {
    this.value = value || null;
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  onKeyDownDelete() {
    if (this.textfieldCleaner.cleaner) {
      this.value = null;
    }
  }
  handleOption(option) {
    var _a;
    this.focusInput();
    this.value = option;
    (_a = this.hostedDropdown) === null || _a === void 0 ? void 0 : _a.updateOpen(false);
  }
  focusInput(preventScroll = false) {
    if (this.nativeFocusableElement) {
      this.nativeFocusableElement.focus({
        preventScroll
      });
    }
  }
}
TuiSelectComponent.ɵfac = function TuiSelectComponent_Factory(t) {
  return new (t || TuiSelectComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_CLEANER), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE), i0.ɵɵdirectiveInject(TUI_ARROW_MODE), i0.ɵɵdirectiveInject(TUI_ITEMS_HANDLERS), i0.ɵɵdirectiveInject(TUI_SELECT_OPTIONS), i0.ɵɵdirectiveInject(TUI_IS_MOBILE));
};
TuiSelectComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiSelectComponent,
  selectors: [["tui-select"]],
  contentQueries: function TuiSelectComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, AbstractTuiNativeSelect, 7);
      i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nativeSelect = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
    }
  },
  viewQuery: function TuiSelectComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
      i0.ɵɵviewQuery(TuiHostedDropdownComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hostedDropdown = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiSelectComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    stringify: "stringify",
    identityMatcher: "identityMatcher",
    valueContent: "valueContent"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiSelectComponent), tuiAsControl(TuiSelectComponent), tuiAsDataListHost(TuiSelectComponent), tuiAsOptionContent(TUI_SELECT_OPTION)], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c4,
  decls: 10,
  vars: 15,
  consts: [["select", ""], ["icon", ""], [1, "t-hosted", 3, "tuiActiveZoneChange", "canOpen", "content"], ["automation-id", "tui-select__textfield", 1, "t-textfield", 3, "keydown.backspace.prevent", "keydown.delete.prevent", "valueChange", "disabled", "editable", "focusable", "invalid", "nativeId", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "tuiTextfieldIcon", "value"], ["ngProjectAs", "select", 5, ["select"], 4, "ngTemplateOutlet"], ["automation-id", "tui-select__value", "ngProjectAs", "tuiContent", "class", "t-value", 5, ["tuiContent"], 4, "ngIf"], [4, "ngIf"], ["ngProjectAs", "select", 5, ["select"]], ["automation-id", "tui-select__value", "ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-value"], ["class", "t-primitive-value", 3, "textContent", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-primitive-value", 3, "textContent"], ["appearance", "icon", "tuiWrapper", "", 1, "t-icon"], [4, "polymorpheusOutlet"]],
  template: function TuiSelectComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r1 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c3);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 2);
      i0.ɵɵlistener("tuiActiveZoneChange", function TuiSelectComponent_Template_tui_hosted_dropdown_tuiActiveZoneChange_0_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onActiveZone($event));
      });
      i0.ɵɵelementStart(1, "tui-primitive-textfield", 3);
      i0.ɵɵlistener("keydown.backspace.prevent", function TuiSelectComponent_Template_tui_primitive_textfield_keydown_backspace_prevent_1_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onKeyDownDelete());
      })("keydown.delete.prevent", function TuiSelectComponent_Template_tui_primitive_textfield_keydown_delete_prevent_1_listener() {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onKeyDownDelete());
      })("valueChange", function TuiSelectComponent_Template_tui_primitive_textfield_valueChange_1_listener($event) {
        i0.ɵɵrestoreView(_r1);
        return i0.ɵɵresetView(ctx.onValueChange($event));
      });
      i0.ɵɵprojection(2);
      i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
      i0.ɵɵtemplate(4, TuiSelectComponent_ng_template_4_Template, 1, 1, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(6, TuiSelectComponent_ng_container_6_Template, 1, 0, "ng-container", 4)(7, TuiSelectComponent_div_7_Template, 2, 5, "div", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(8, TuiSelectComponent_ng_template_8_Template, 2, 1, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const select_r4 = i0.ɵɵreference(5);
      const icon_r5 = i0.ɵɵreference(9);
      i0.ɵɵproperty("canOpen", ctx.interactive && !ctx.nativeDropdownMode)("content", ctx.datalist || "");
      i0.ɵɵadvance();
      i0.ɵɵproperty("disabled", ctx.computedDisabled)("editable", false)("focusable", ctx.computedFocusable)("invalid", ctx.computedInvalid)("nativeId", ctx.nativeId)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("tuiTextfieldIcon", ctx.arrow ? icon_r5 : "")("value", ctx.computedValue);
      i0.ɵɵadvance(5);
      i0.ɵɵproperty("ngTemplateOutlet", select_r4);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.value !== null);
    }
  },
  dependencies: [i1$1.TuiHostedDropdownComponent, i1$1.TuiPrimitiveTextfieldComponent, i2.TuiActiveZoneDirective, i1$1.TuiPrimitiveTextfieldDirective, i1$1.TuiTextfieldIconDirective, i3.NgIf, i3.NgTemplateOutlet, i4$1.PolymorpheusOutletDirective, i1$1.TuiWrapperDirective],
  styles: ["[_nghost-%COMP%]{display:block;border-radius:var(--tui-radius-m);text-align:left}[_nghost-%COMP%]:not(._readonly)     input:not(:disabled){cursor:pointer}._readonly[_nghost-%COMP%]     input{cursor:default}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-textfield[_ngcontent-%COMP%]{border-radius:inherit;text-align:inherit}.t-value[_ngcontent-%COMP%]{display:flex;width:100%;align-items:center}.t-primitive-value[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectComponent, [{
    type: Component,
    args: [{
      selector: 'tui-select',
      templateUrl: './select.template.html',
      styleUrls: ['./select.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiSelectComponent), tuiAsControl(TuiSelectComponent), tuiAsDataListHost(TuiSelectComponent), tuiAsOptionContent(TUI_SELECT_OPTION)],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i5.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i1$1.TuiTextfieldCleanerDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_CLEANER]
      }]
    }, {
      type: i1$1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ARROW_MODE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ITEMS_HANDLERS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_SELECT_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }];
  }, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    hostedDropdown: [{
      type: ViewChild,
      args: [TuiHostedDropdownComponent]
    }],
    nativeSelect: [{
      type: ContentChild,
      args: [AbstractTuiNativeSelect, {
        static: true
      }]
    }],
    stringify: [{
      type: Input
    }],
    identityMatcher: [{
      type: Input
    }],
    valueContent: [{
      type: Input
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }]
  });
})();
class TuiSelectDirective extends AbstractTuiTextfieldHost {
  get readOnly() {
    return true;
  }
  get value() {
    return this.host.computedValue;
  }
  get stringify() {
    return this.host.stringify;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
}
TuiSelectDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiSelectDirective_BaseFactory;
  return function TuiSelectDirective_Factory(t) {
    return (ɵTuiSelectDirective_BaseFactory || (ɵTuiSelectDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiSelectDirective)))(t || TuiSelectDirective);
  };
})();
TuiSelectDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiSelectDirective,
  selectors: [["tui-select"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiSelectDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-select',
      providers: [tuiAsTextfieldHost(TuiSelectDirective)]
    }]
  }], null, null);
})();
class TuiSelectModule {}
TuiSelectModule.ɵfac = function TuiSelectModule_Factory(t) {
  return new (t || TuiSelectModule)();
};
TuiSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiSelectModule
});
TuiSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, PolymorpheusModule, TuiActiveZoneModule, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiSelectOptionModule, TuiArrowModule, TuiWrapperModule, TuiTextfieldControllerModule, TuiDataListWrapperModule, TuiDataListModule, TuiStringifyContentPipeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, PolymorpheusModule, TuiActiveZoneModule, TuiPrimitiveTextfieldModule, TuiHostedDropdownModule, TuiSelectOptionModule, TuiArrowModule, TuiWrapperModule, TuiTextfieldControllerModule, TuiDataListWrapperModule, TuiDataListModule, TuiStringifyContentPipeModule],
      declarations: [TuiSelectComponent, TuiSelectDirective, TuiNativeSelectComponent, TuiNativeSelectGroupComponent],
      exports: [TuiSelectComponent, TuiSelectDirective, TuiTextfieldComponent, TuiNativeSelectComponent, TuiNativeSelectGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_SELECT_DEFAULT_OPTIONS, TUI_SELECT_OPTIONS, TuiNativeSelectComponent, TuiNativeSelectGroupComponent, TuiSelectComponent, TuiSelectDirective, TuiSelectModule, tuiSelectOptionsProvider };
